import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";

import Images from '../img/Images'


const HomeJurisdiction = (props) => (
	<StaticQuery
		query={graphql`
			query HPJurisdictions {
				homeContent: datoCmsHome {
					jurisdictionTitle
					jurisdictionDescription
				}
			}
		`}
		render={data => {

			let title = (data.homeContent.jurisdictionTitle) ? data.homeContent.jurisdictionTitle : '';
			let description = (data.homeContent.jurisdictionDescription) ? data.homeContent.jurisdictionDescription : '';

			return (
				<div className="explore-jurisdiction">
					<div className="centre-container">
						<div className="inner grid-ten -centre">
							<div className='intro-block'>
								<h2 className="intro-heading">\ {title}</h2>
								<p className="intro-copy">{description}</p>
							</div>
							<div className="map-container">
								<img className="map-image" src={Images.mapJurisdiction} alt='map' />

								<p className="state-asterix -wa-asterix">?</p>
								<p className="state -wa">&#8226; Western Australia</p>
								<div className="circle -wa-circle"></div>
								<p className="state-message -wa-message">Our report does not cover Western Australia</p>
								<p className="state-asterix -nt-asterix">?</p>
								<p className="state -nt">&#8226; Northern Territory</p>
								<div className="circle -nt-circle"></div>
								<p className="state-message -nt-message">Our report does not cover the Northern Territory</p>
								<a href="jurisdiction/queensland" className="state -qld">&#8226; Queensland</a>
								<div className="circle -qld-circle"></div>
								<a href="jurisdiction/new-south-wales" className="state -nsw">&#8226; New South Wales</a>
								<div className="circle -nsw-circle"></div>
								<a href="jurisdiction/australian-capital-territory" className="state -act">&#8226; Australian Capital Territory</a>
								<div className="circle -act-circle"></div>
								<a href="jurisdiction/victoria" className="state -vic">&#8226; Victoria</a>
								<div className="circle -vic-circle"></div>
								<a href="jurisdiction/tasmania" className="state -tas">&#8226; Tasmania</a>
								<div className="circle -tas-circle"></div>
								<a href="jurisdiction/south-australia" className="state -sa">&#8226; South Australia</a>
								<div className="circle -sa-circle"></div>
							</div>

							<div className="explore-links-container">
								<a className="explore-links" href="jurisdiction/queensland">+ Queensland</a>
								<a className="explore-links" href="jurisdiction/new-south-wales">+ New South Wales</a>
								<a className="explore-links" href="jurisdiction/victoria">+ Victoria</a>
								<a className="explore-links" href="jurisdiction/australian-capital-territory">+ Australian Capital Territory</a>
								<a className="explore-links" href="jurisdiction/tasmania">+ Tasmania</a>
								<a className="explore-links" href="jurisdiction/south-australia">+ South Australia</a>
							</div>
						</div>
					</div>
				</div>
			)
		}}
	/>
)

export default HomeJurisdiction
