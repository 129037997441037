import React from 'react'
import { Link, graphql } from 'gatsby'
import Masonry from 'react-masonry-component'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Layout from "../components/layout"
import HomeHero from "../components/home-hero"
import HomeJurisdiction from "../components/home-jurisdiction"
import KeyMessages from "../components/key-messages"
import Header from '../components/header'
import FeatureArticles from '../components/featureArticles'

const IndexPage = ({ data: { home }, location }) => {
	return (
		<Layout>
			<Header
				home
				location={location}
				title={home.title}
			/>
			<HomeHero
				data={home}
			/>
			<KeyMessages location={location} />
			<FeatureArticles />
			<HomeJurisdiction
				data={home}
			/>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	query HomeQuery {
		home: datoCmsHome {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			title
			description
			heroImage {
					url
			}
			mobileHeroImage {
				url
			}
		}
	}
`
