import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Images from '../img/Images'

const FeatureArticle = ({content}) => {
	return (
		<div className="article_main_container">
			<div className="diagonal_container">
				<Link to={`/feature-article/${content.slug}`}>
					<div className="inside">
						<div className='inner'>
							<h2>{content.title}</h2>
							<p>{content.description}</p>
						</div>

						<div className='feature_link'>
							<p>READ MORE</p>
						</div>
					</div>
				</Link>
			</div>
		</div>
	)
}

const FeatureArticles = (props) => (
	<StaticQuery
		query={graphql`
			query featureArticleListQuery{
				articles: allDatoCmsFeatureArticle {
					edges {
						node {
							title
							id
							description
							slug
						}
					}
				}
				homeContent: datoCmsHome {
					featureArticlesTitle
					featureArticlesDescription
				}
			}
		`}
		render={data => {
			const articles = data.articles.edges;
			const featureArticle = data.homeContent;
			const display = articles.map(function(obj, i) {
				return <FeatureArticle content={obj.node} key={i} />
			})
			const slicedArray = display.slice(0, 3);
			return (
				<div className='feature-article-container'>
					<div className="centre-container">
						<div className="inner grid-ten -centre">

							<div className='intro-block'>
								<h2 className="intro-heading">\ {featureArticle.featureArticlesTitle}</h2>
								<p className="intro-copy"> {featureArticle.featureArticlesDescription}</p>
							</div>
							<div className='article-container'>
								{slicedArray}
							</div>
						</div>
					</div>
				</div>
			)
		}}
	/>
)

FeatureArticles.propTypes = {
	// siteTitle: PropTypes.string,
}

FeatureArticles.defaultProps = {
	// siteTitle: ``,
}

export default FeatureArticles
