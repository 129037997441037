import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'

class HomeHero extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { data } = this.props;
		return (
			<div className="hero">
				<div className="centre-container -remove-padding-top">
					<div className="inner grid-ten -centre">
						<BreakpointProvider>
							<Breakpoint large up>
								<img
									src={data.heroImage.url} alt={ data.title }
								/>
							</Breakpoint>

							<Breakpoint medium down>
								<img
									src={data.mobileHeroImage.url} alt={ data.title }
								/>
							</Breakpoint>
						</BreakpointProvider>
						<p>{ data.description}</p>
					</div>
				</div>
			</div>
		)
	}
}

export default HomeHero;
