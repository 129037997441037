import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Images from '../img/Images'
import MessagesBlock from '../components/messagesBlock'


const KeyMessages = (props) => (
	<StaticQuery
		query={graphql`
			query KeyMessageListQuery{
				keyMessages: datoCmsHome {
					keyMessagesTitle
					keyMessagesDescription
					nationalJurisdiction {
					title
					bodyContent {
						... on DatoCmsHeading {
							id
							keyMessage
						}
						... on DatoCmsText {
							id
							sectionTitle
							text
							textNode {
								childMarkdownRemark {
								html
								}
							}
							id
						}
						... on DatoCmsIframe {
							id
							sectionTitle
							link
						}
						... on DatoCmsImage {
							sectionTitle
							id
							image {
								url
								format
								size
								fluid(maxWidth: 300, imgixParams: { fm: "jpg", auto: "compress" }) {
								...GatsbyDatoCmsSizes
								}
							}
						}
						... on DatoCmsTable {
							sectionTitle
							id
							table {
								file {
								csv
								}
							}
						}
						... on DatoCmsDatum {
							sectionTitle
							id
							chart {
								chartDescriptionNode {
									childMarkdownRemark {
										html
									}
								}
								chartType {
									title
								}
								file {
									csv
								}
							}
						}
					}
					}
				}
			}
		`}
		render={data => {
			let {
				keyMessages,
			} = data;
			const nationalJurisdiction = keyMessages.nationalJurisdiction;
			const { location } = props;
			return (
				<MessagesBlock
					content={nationalJurisdiction}
					keyMessagesTitle={keyMessages.keyMessagesTitle}
					keyMessagesDescription={keyMessages.keyMessagesDescription}
					location='Home'
					slug='/'
					hash={location.hash}
					path={location.pathname}
				/>
			)
		}}
	/>
)

KeyMessages.propTypes = {
	// siteTitle: PropTypes.string,
}

KeyMessages.defaultProps = {
	// siteTitle: ``,
}

export default KeyMessages
